/* eslint-disable @typescript-eslint/naming-convention */
import { DatePicker } from 'ngx-sumax-erp-component/lib/date-picker/date-picker';
import { DateTimePicker } from 'ngx-sumax-erp-component/lib/date-time-picker/date-time-picker';
import { DateTimePickerEditable } from 'ngx-sumax-erp-component/lib/utils/DateTimePickerEditable';
import { InputText } from 'ngx-sumax-erp-component/lib/input-text/input-text';
import { NgSelectMultiple } from 'ngx-sumax-erp-component/lib/ng-select-multiple/ng-select-multiple';
import { NgSelectSimple } from 'ngx-sumax-erp-component/lib/ng-select-simple/ng-select-simple';
import { TextArea } from 'ngx-sumax-erp-component/lib/text-area/text-area';
import { InputNumber } from 'ngx-sumax-erp-component/lib/input-number/input-number';

export const DEFAULT_CONFIG_INPUT_TEXT: InputText = {
	format: 'M',
	showLabel: true,
	btnEnable: false,
	bold: false,
	textRight: false,
	validatorMessage: true,
	tooltipMessage: false,
	submitted: true,
	validatorAlert: false,
};

export const DEFAULT_CONFIG_INPUT_NUMBER: InputNumber = {
	decimals: -1,
	formatCero: false,
	btnEnable: false,
	bold: false,
	validatorMessage: true,
	tooltipMessage: false,
	submitted: true,
	validatorAlert: false,
};

export const DEFAULT_CONFIG_NGSELECT_SIMPLE: NgSelectSimple = {
	validatorMessage: true,
	tooltipMessage: false,
	openWhenFocus: false,
	searchBy: 'value',
	markFirstItem: false,
	submitted: true,
	searchable: true,
	validatorAlert: false,
	upperCase: false,
	appendTo: 'body',
	loadingText: 'Cargando...',
	clearAllText: 'Limpiar',
	markFirst: true,
	clearable: false,
	clearOnBackspace: false,
	virtualScroll: true,
	openOnEnter: false,
};

export const DEFAULT_CONFIG_NGSELECT_MULTIPLE: NgSelectMultiple = {
	showlimit: 2,
	Header: true,
	Footer: true,
	markFirst: false,
	openWhenFocus: false,
	validatorMessage: true,
	tooltipMessage: false,
	validatorAlert: false,
	submitted: true,
	appendTo: 'body',
	loadingText: 'Cargando...',
};

export const DEFAULT_CONFIG_TEXT_AREA: TextArea = {
	format: 'M',
	showLabel: true,
	validatorMessage: true,
	tooltipMessage: false,
	submitted: true,
	validatorAlert: false,
};

export const DEFAULT_CONFIG_DATE_PICKER: DatePicker = {
	showLabel: true,
	autocomplete: 'off',
	noAutoCompleteNull: true,
	focusInitial: false,
	validatorMessage: true,
	tooltipMessage: false,
	submitted: true,
	validatorAlert: false,
};

export const DEFAULT_CONFIG_DATE_TIME_PICKER: DateTimePicker = {
	inputDatetimeFormat: 'dd/MM/yyyy HH:mm',
	showLabel: true,
	openWhenFocus: false,
	goNextWhenSelect: false,
	validatorMessage: true,
	tooltipMessage: false,
	submitted: true,
};

export const DEFAULT_CONFIG_DATE_TIME_PICKER_EDITABLE: DateTimePickerEditable = {
	showLabel: true,
	validatorMessage: true,
	tooltipMessage: false,
};
