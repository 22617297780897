// Angular
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
	selector: 'kt-portlet-body',
	template: `
		<!-- <div class="kt-portlet-body-modal mat-dialog-content" *ngIf="modal">hola</div> -->
		<ng-content></ng-content>

		<!-- <ng-container *ngIf="!modal">
			<ng-content></ng-content>
		</ng-container> -->
	`,
	host: {
		class: 'kt-portlet-body',
		'[class.kt-portlet-body-scroll]': '!modalScroll',
		'[class.kt-portlet-body-modal]': 'modalScroll',
		'[class.mat-dialog-content]': 'modalScroll',
	},
	styles: [
		`
			.kt-portlet-body {
				display: block;
			}

			.kt-portlet-body-scroll {
				padding: 0.5em 1.4em !important; /* quitar el important*/
				overflow: hidden auto;
			}

			.kt-portlet-body-modal {
				padding: 0.5em 1.4em !important; /* quitar el important*/
				overflow-y: auto;
				max-height: 80vh;
			}
		`,
	],
	encapsulation: ViewEncapsulation.None,
	// changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortletBodyComponent implements OnInit {
	// Public properties
	// @HostBinding('class') classList = 'kt-portlet__body';
	/**@deprecated */
	@Input() customClass!: string;
	@Input() class!: string;

	@Input() modalScroll = false;

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		// if (this.customClass) {
		// 	this.classList += ' ' + this.customClass;
		// }
		// if (this.class) {
		// 	this.classList += ' ' + this.class;
		// }
	}
}
