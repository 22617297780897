/* angular:styles/component:css;5fc9dafbe5975aa41c3c68f3f6a00eedb8ead5325ac73c626b87a85b264d6694;/builds/sumax/erp/sumax-erp-frontend/gen/sumax-erp-frontend-acuerdocomercial/src/@partials/content/general/portlet/portlet-body.component.ts */
.kt-portlet-body {
  display: block;
}
.kt-portlet-body-scroll {
  padding: 0.5em 1.4em !important;
  overflow: hidden auto;
}
.kt-portlet-body-modal {
  padding: 0.5em 1.4em !important;
  overflow-y: auto;
  max-height: 80vh;
}
/*# sourceMappingURL=portlet-body.component.css.map */
